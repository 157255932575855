
(() => {
    const favicons = document.querySelectorAll('link[rel="icon"]');
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const darkModeOn = darkModeMediaQuery.matches;
      favicons.forEach((link) => {      
        link.href = darkModeOn ? link.dataset.hrefDark : link.dataset.hrefLight;   
      });
})();
  

function handleSolutions(){
    const buttons = document.querySelectorAll('.solutions__select-buttons>.selectButtons__button');
    const contents = document.querySelectorAll('.solutions>.viewport>.viewport__content');
    const bg = document.querySelector('.solutions__select-buttons>.selectButtons__background');
    if (!bg) {
        return;
    }
    bg.style.left = `${buttons[0].offsetLeft-20}px`;
    bg.style.width = `${buttons[0].offsetWidth+40}px`;
    let id = 0;
    buttons.forEach(function(button,buttonIndex){
        button.addEventListener('click',(el)=>{
            buttons.forEach(el=>el.classList.remove('--active'));
            el.target.classList.add('--active');
            bg.style.left = `${el.target.offsetLeft-20}px`;
            bg.style.width = `${el.target.offsetWidth+40}px`;
            console.log(el.target.offsetWidth);
            contents.forEach(el=>el.classList.remove('--active'));
            contents[buttonIndex].classList.add('--active');
        });
    })
}
function handleSolutionsSelect(){
    const select = document.querySelector('.selectButtons__select');
    const contents = document.querySelectorAll('.solutions>.viewport>.viewport__content');
    if (select && contents) {
        select.addEventListener('change',(e)=>{
            contents.forEach(el=>el.classList.remove('--active'));
            switch (e.target.value){
                case "ecommerce":
                    contents[1].classList.add('--active');
                    break;
                case "konfiguratory":
                    contents[0].classList.add('--active');
                    break;
                case "b2b":
                    contents[2].classList.add('--active');
                    break;
            }
        });
    }
}
function handleOurSolutions(){
    if (detectIfMobile){
        const sections = [
            document.getElementById('configurators'),
            document.getElementById('ecommerce'),
            document.getElementById('b2b'),
        ];
        const inputOptions = [
            'konfiguratory',
            'ecommerce',
            'b2b',
        ];
        const selectInput = document.querySelector('.selectButtons__select');
        if (selectInput) {
            window.addEventListener('scroll',(e)=>{
                let filteredSections = sections.filter(e=>e.offsetTop+e.offsetHeight>window.scrollY);
                selectInput.value = inputOptions[sections.indexOf(filteredSections[0])]; 
            });
            selectInput.addEventListener('change',(e)=>{
                if(e.target.value && inputOptions.indexOf(e.target.value)!==-1){
                    if (inputOptions.indexOf(e.target.value)===0){
                        window.scroll(0,sections[inputOptions.indexOf(e.target.value)].offsetTop-100);
                    } else {
                        window.scroll(0,sections[inputOptions.indexOf(e.target.value)].offsetTop);
                    }
                }
            });
        }
    }
}
function detectIfMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}
function handleHamburger(){
    const navButton = document.getElementById('openNav');
    const sideMenu = document.querySelector('.header_container');
    navButton.addEventListener('click',e=>{
        sideMenu.classList.toggle('--active');
    })
}
function dropDownMenu(){
    const dropDownActivatorsLinks = document.querySelectorAll('.header__nav-menu > a');
    const dropDownActivators = document.querySelectorAll('.header__nav-menu-top');
    const dropDownActivatorsArrow = document.querySelectorAll('.header__nav-menu > a > svg');
    dropDownActivatorsLinks.forEach(el=>{
        el.addEventListener('click',e=>{
            e.target.parentNode.classList.toggle('--active');
        },true)
    });
    dropDownActivators.forEach(el=>{
        el.addEventListener('click',e=>{
            e.target.closest('.header__nav-menu').classList.toggle('--active');
        },true)
    });

}


function languageSelect(){
    const selects = document.querySelectorAll('.language_select');
    Array.from(selects).forEach(e=>{
        window.add
    })
}


const handleGoToTopButton = () => {
    const goToTopButton = document.querySelector('.moveTop');
    window.addEventListener('scroll',e=>{
        if (window.scrollY > 400){
            goToTopButton.classList.add('--active');
        } else {
            goToTopButton.classList.remove('--active');
        }
    })
}

window.onload = function() {
  handleSolutions();
};
handleHamburger();
handleSolutionsSelect();
dropDownMenu();
handleGoToTopButton();
handleOurSolutions();
// languageSelect();
const sliderReferences = new Swiper('.opinions__slider', {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 0,
    navigation: {
      nextEl: '.opinions__arrow.--right',
      prevEl: '.opinions__arrow.--left',
    },
    pagination: {
        el: '.opinions__pagination',
        clickable: true,
        touchable: true,
    },
})